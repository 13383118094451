export const useScheduleStore = (set, get) => ({
  // state
  scheduleComponentStates: 'coming-soon', // default | time | scheduled | published | setting | coming-soon
  scheduleDay: [
    { day: 'Sun', active: true },
    { day: 'Mon', active: true },
    { day: 'Tue', active: true },
    { day: 'Wed', active: true },
    { day: 'Thu', active: true },
    { day: 'Fri', active: true },
    { day: 'Sat', active: true }
  ],
  scheduleTime: [
    { time: '09:00', active: true },
    { time: '13:00', active: false },
    { time: '18:00', active: false },
    { time: '22:00', active: false }
  ],
  videosToSchedule: [
    {
      id: 'f0ff3fbf-a2da-4829-bb93-a89b203d9be5',
      date: 'Sep 19',
      scheduleDay: 'Monday',
      scheduleTime: '9:00am',
      video: {
        id: 'f0ff3fbf-a2da-4829-bb93-a89b203d9be5',
        proportion: 0.2,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Morning_Walk_-_Jingle_Punks.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/CLGHUVC7TZ/zLHj6.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 3.869583,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857924/Woxo/Idea2video/data_set/Taylor_Swift/new/2998427767_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-out'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.228271
              }
            ]
          }
        ],
        meta: {
          title: 'Fun Facts About Taylor Swift',
          description: 'Learn some fun facts about Taylor Swift in this short video!',
          hashtags: '#TaylorSwift #FunFacts #Billboard200 #shorts',
          mediaDescription: 'A picture of Taylor Swift smiling and looking at the camera.',
          category: 'Fun Facts'
        }
      }
    },
    {
      id: 'f0ff3fbf-a2da-4829-bb93-a89b203d9be5',
      date: 'Sep 20',
      scheduleDay: 'Tuesday',
      scheduleTime: '9:00am',
      video: {
        id: '95dfd492-a421-4a5e-bc5c-884da7ed86b1',
        proportion: 0.28,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Feels_Good_-_MOODHALO.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/vYgFvwwlWi/q25JI.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 4.5,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683859676/Woxo/Idea2video/data_set/Taylor_Swift/new/2263577504_A_picture_of_Taylor_Swift_with_a_gentle_smile_and__xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-in-slider'
                },
                optimizedMedia: false
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'DID YOU KNOW THIS *QUOTE* ABOUT ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 1.032625
              }
            ]
          }
        ],
        meta: {
          title: 'Taylor Swift Quote on Kindness',
          description:
            'This video is a short quote from Taylor Swift about being kind to others and leaving a positive legacy.',
          hashtags: '#taylorswift #kindness #legacy #inspiration #shorts',
          mediaDescription:
            'A picture of Taylor Swift with a gentle smile and her hands clasped together.',
          category: 'Quotes'
        }
      }
    }
  ],
  videosPublished: [
    {
      id: 'f0ff3fbf-a2da-4829-bb93-a89b203d9be5',
      date: 'Sep 19',
      scheduleDay: 'Monday',
      scheduleTime: '9:00am',
      video: {
        id: 'f0ff3fbf-a2da-4829-bb93-a89b203d9be5',
        proportion: 0.2,
        width: 1080,
        height: 1920,
        customFonts: [
          {
            fontFamily: 'Montserrat-ExtraBold',
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
          }
        ],
        audioTracks: [
          {
            path: 'https://res.cloudinary.com/dakp804eh/video/upload/v1674146668/woxo-video/audios-normalized/Happy/Morning_Walk_-_Jingle_Punks.mp3',
            mixVolume: 0.2
          },
          {
            path: 'https://vod.front10.cloud/output/CLGHUVC7TZ/zLHj6.mp3',
            loop: false
          }
        ],
        clips: [
          {
            duration: 3.869583,
            transition: {
              duration: 1,
              name: 'random',
              easing: null
            },
            layers: [
              {
                path: 'https://res.cloudinary.com/dakp804eh/image/upload/v1683857924/Woxo/Idea2video/data_set/Taylor_Swift/new/2998427767_A_picture_of_Taylor_Swift_smiling_and_looking_at_t_xl-beta-v2-2-2.png',
                type: 'woxo-image',
                resizeMode: 'cover',
                animation: true,
                animationSettings: {
                  type: 'ken-burns-zoom-out'
                }
              },
              {
                top: 'bottom',
                textAlign: 'center',
                fill: '#FFFFFF',
                backgroundColor: '#FFFE00',
                customFont: {
                  fontFamily: 'Montserrat-ExtraBold',
                  path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
                },
                fontSize: 90,
                fontWeight: 600,
                stroke: '#000000',
                strokeWidth: 8,
                shadow: 'rgba(0,0,0,.20) 0px 0px 20px',
                type: 'woxo-custom-text-basic',
                hide: false,
                fontFamily: 'Montserrat-ExtraBold',
                text: 'HEY ',
                width: 1080,
                height: 1920,
                start: 0,
                stop: 0.228271
              }
            ]
          }
        ],
        meta: {
          title: 'Fun Facts About Taylor Swift',
          description: 'Learn some fun facts about Taylor Swift in this short video!',
          hashtags: '#TaylorSwift #FunFacts #Billboard200 #shorts',
          mediaDescription: 'A picture of Taylor Swift smiling and looking at the camera.',
          category: 'Fun Facts'
        }
      }
    }
  ],

  // actions
  setScheduleComponentStatesAction: (scheduleComponentStates) => set({ scheduleComponentStates }),

  setScheduleDayStatesAction: (day) => {
    let _scheduleDay = [...get().scheduleDay];
    _scheduleDay.map((d) => {
      if (d.day === day.day) {
        d.active = !d.active;
      }
    });
    set({ scheduleDay: _scheduleDay });
  },

  setScheduleTimeStatesAction: (idx, time) => {
    let _scheduleTime = [...get().scheduleTime];
    _scheduleTime[idx].time = time;
    set({ scheduleTime: _scheduleTime });
  },

  setScheduleActiveTimeStatesAction: (idx) => {
    let _scheduleTime = [...get().scheduleTime];
    _scheduleTime[idx].active = !_scheduleTime[idx].active;
    set({ scheduleTime: _scheduleTime });
  },

  deleteVideoToScheduleAction: (idx) => {
    let _videosToSchedule = [...get().videosToSchedule];

    _videosToSchedule.splice(idx, 1);
    set({ videosToSchedule: _videosToSchedule });
  },

  interestInScheduleAction: () => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'interest-in-schedule'
    });
  },

  notFullyInterestedInScheduleAction: () => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'not-fully-interested-in-schedule'
    });
  },

  veryInterestedInScheduleAction: () => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'very-interested-in-schedule'
    });

    const url = 'https://forms.gle/SqX2nL4wm6Dko3sx7';
    window.open(url, '_blank');
  }
});
