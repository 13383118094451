import { clone } from '~/helpers/javascript';
import { cancelGeneration } from '~/services/video.service';
import { db } from '../helpers/db';
import { publishVideos, normalizeVideos } from '../helpers/reely';

import {
  generate,
  generateDownloadVideoZipOrder,
  uploadMedias
} from '../helpers/generation.services';
import {
  replaceUploadedMediaInVideos,
  addWaterMarkInVideos,
  validateFulfilled
} from '../helpers/helper';

export const useDownloadStore = (set, get) => ({
  // state
  videosToDownload: [],
  downloadPercent: 0,
  downloadStatus: 'default', //default | downloading | finished | error | cancel
  componentStates: 'default', // default | upgrade | downloading | finished | error
  lastJobid: null,
  mediasToUpload: [],
  watermarkPreview: [],
  downloadTime: '00:00',
  downloadTimeCron: null,

  // actions
  setComponentStatesAction: (componentStates) => set({ componentStates }),

  cancelDownloadAction: () => {
    set({ downloadStatus: 'cancel' });
    const _job = { id: get().lastJobid };
    Object.freeze(_job);

    if (_job.id) {
      cancelGeneration(_job.id);
      set({ downloadStatus: 'default' });
    }

    set({
      componentStates: 'default',
      downloadPercent: 0,
      downloadTime: '00:00',
      downloadTimeCron: null
    });
  },

  deleteVideoToDownloadAction: async (idx) => {
    let _videosToDownload = [...get().videosToDownload];
    let _mediasToUpload = [...get().mediasToUpload];

    const _videoId = _videosToDownload[idx].id;

    _videosToDownload.splice(idx, 1);
    set({ videosToDownload: _videosToDownload });

    if (_videosToDownload.length === 0) set({ componentStates: 'default' });

    _mediasToUpload.forEach((item, index, object) => {
      if (item.id === _videoId) {
        object.splice(index, 1);
      }
    });

    await db.mediasToUpload.put({
      id: 1,
      medias: _mediasToUpload
    });

    await db.videosToDownload.put({
      id: 1,
      videos: _videosToDownload
    });
  },

  addVideoToDownloadAction: async (sectionIdx, videoIdx, startDownload = false) => {
    if (!get().user) {
      get().redirectNotLoggedInAction('login');
    } else {
      if (get().downloadStatus === 'finished') {
        set({
          videosToDownload: [],
          downloadStatus: 'default',
          componentStates: 'default'
        });
      }

      const video = clone(get().sections[sectionIdx].videos[videoIdx]);
      video.userPrompt = get().prompt;
      const category = get().sections[sectionIdx].category;

      const isFound = get().videosToDownload.some((v) => {
        if (v.id === video.id) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        if (
          get().background.value.indexOf('ai-art') !== -1 &&
          get().sections[sectionIdx]?.medias?.length &&
          get().sections[sectionIdx]?.medias[videoIdx]
        ) {
          const _mediasToUpload = [...get().mediasToUpload];
          _mediasToUpload.push({
            id: video.id,
            medias: get().sections[sectionIdx].medias[videoIdx]
          });

          await db.mediasToUpload.put({
            id: 1,
            medias: _mediasToUpload
          });

          set({ mediasToUpload: _mediasToUpload });
        }

        const _videos = clone(get().videosToDownload);
        _videos.push(video);
        set({ videosToDownload: _videos });

        await db.videosToDownload.put({
          id: 1,
          videos: _videos
        });

        const totalVideosBtn = document.querySelector('#btn__total-videos');
        if (totalVideosBtn) {
          totalVideosBtn.classList.add('shake');
          setTimeout(() => {
            totalVideosBtn.classList.remove('shake');
          }, 500);
        }

        const _label =
          '@prompt_' +
          get().prompt +
          '@category_' +
          category +
          '@background_' +
          get().background.label +
          '@music_' +
          get().music.label +
          '@language_' +
          get().language.label +
          '@style_' +
          get().style +
          '@type_' +
          get().type +
          '@color_' +
          get().color +
          '@textAlign_' +
          get().textAlign +
          '@verticalAlign_' +
          get().verticalAlign;
        get().trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'add-video-to-download',
          label: _label
        });
      }

      if (startDownload) get().initDownloadAction();
    }
  },

  startDownloadAction: async () => {
    if (get().plan) {
      get().initDownloadAction();
    } else {
      const _watermarkPreview = addWaterMarkInVideos(clone(get().videosToDownload), 0.14);
      set({ watermarkPreview: _watermarkPreview });
      set({ componentStates: 'upgrade' });
    }
  },

  initDownloadAction: async () => {
    get().startDownloadTimeAction();

    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'download-started',
      label: `@videos_${get().videosToDownload.length}`
    });

    set(
      {
        componentStates: 'downloading',
        downloadStatus: 'downloading',
        downloadPercent: 0,
        lastJobid: null
      },
      false,
      'Update states'
    );

    setTimeout(() => {
      set({ downloadPercent: 15 });
    }, 100);

    let _videosToDownload = clone(get().videosToDownload);

    // --- UPLOAD ---
    if (get().mediasToUpload.length !== 0) {
      const _mediasToUpload = await uploadMedias(clone(get().mediasToUpload));
      const fulfilled = validateFulfilled(_mediasToUpload);

      if (fulfilled) {
        _videosToDownload = replaceUploadedMediaInVideos(_mediasToUpload, _videosToDownload);
      } else {
        set({ downloadStatus: 'error', componentStates: 'error' });
        get().stopDownloadTimeAction();
        throw 'Error uploading';
      }
    }

    if (!get().plan) {
      _videosToDownload = addWaterMarkInVideos(_videosToDownload);
    }

    // --- GENERATE ---
    const setStatusAction = (resp) => {
      if (get().downloadStatus === 'downloading') {
        if (get().lastJobid !== resp.jobid) {
          set({
            lastJobid: resp.jobid
          });
        } else {
          const _percent =
            (resp.totalDone / (_videosToDownload.length + _videosToDownload.length / 3.5)) * 100;
          if (_percent > 15) set({ downloadPercent: _percent });

          if (resp.totalRunning === 0 && resp.totalDone === 0) {
            set({ downloadStatus: 'error', componentStates: 'error' });
            get().stopDownloadTimeAction();
            throw 'Error generating';
          }
        }
      } else if (get().downloadStatus === 'cancel') {
        // TODO: Improve this, in case the user interacts very quickly
        cancelGeneration(resp.jobid);
      }
    };
    let generatedVideos = [];
    await generate(
      _videosToDownload.map((s, i) => ({
        ...s,
        title: `Video #${i + 1}`,
        proportion: 1
      })),
      setStatusAction
    )
      .then((resp) => {
        generatedVideos = resp.data;
      })
      .catch((error) => {
        set({ downloadStatus: 'error', componentStates: 'error' });
        get().stopDownloadTimeAction();
        throw error;
      });

    if (get().downloadStatus === 'downloading') {
      const result = await generateDownloadVideoZipOrder(get().lastJobid).catch((error) => {
        set({ downloadStatus: 'error', componentStates: 'error' });
        get().stopDownloadTimeAction();
        throw error;
      });

      if (!result?.response?.Location) {
        throw 'Error when try to download video';
      }

      const url = result.response.Location;
      if (url && url !== '') {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

      get().trackingAction({
        event: 'onButtonClick',
        category: 'idea-to-video',
        action: 'download-finished',
        label: `${window.location.origin}/watch/${get().lastJobid}`
      });

      // enviar videos pal api aki salvar video aki -> generatedVideos
      try {
        const normalizedVideos = normalizeVideos(_videosToDownload, generatedVideos, get().user);
        publishVideos(normalizedVideos);
      } catch (error) {
        console.error(error);
      }

      get().stopDownloadTimeAction();

      set({
        downloadStatus: 'finished',
        componentStates: 'finished',
        downloadPercent: 100,
        videosToDownload: [],
        mediasToUpload: [],
        watermarkPreview: []
      });

      await db.mediasToUpload.put({
        id: 1,
        medias: []
      });

      await db.videosToDownload.put({
        id: 1,
        videos: []
      });
    }
  },

  downloadWithWatermarkAction: async () => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'download-with-watermark'
    });
    get().initDownloadAction();
  },

  upgradePlanAction: (action, fn) => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: `upgrade-plan-${action}`
    });

    localStorage.setItem('ideaToVideoState', JSON.stringify(get()));

    if (fn) fn();

    // const url = 'https://buy.stripe.com/28obML206amG5d6146';

    // const element = document.createElement('a');
    // element.setAttribute('href', url);
    // element.style.display = 'none';
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  },

  startDownloadTimeAction: () => {
    set({ downloadTime: '00:00' });
    get().stopDownloadTimeAction();
    const _downloadTimeCron = setInterval(() => {
      get().setDownloadTimeAction();
    }, 1000);
    set({ downloadTimeCron: _downloadTimeCron });
  },

  stopDownloadTimeAction: () => {
    clearInterval(get().downloadTimeCron);
  },

  setDownloadTimeAction: () => {
    let downloadTime = get().downloadTime;
    downloadTime = downloadTime.split(':');
    let minute = Number(downloadTime[0]);
    let second = Number(downloadTime[1]);

    if (second < 60) {
      second++;
    }
    if (second === 60) {
      second = 0;
      minute++;
    }

    let time = '';

    if (minute < 10) time = '0' + minute;
    else time = minute;

    if (second < 10) time = time + ':0' + second;
    else time = time + ':' + second;

    set({ downloadTime: time });
  },

  redirectSignUpAction: () => {
    localStorage.setItem('ideaToVideoState', JSON.stringify(get()));
    get().showSignUpmodalAction(window.location.pathname, 'signup');
  }
});
